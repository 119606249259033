<template>
  <LayoutSection
    v-if="items?.length || isEditing"
    :id="id"
    :title="title"
    padding
    bg-gray
    class="paragraph--padded"
  >
    <div class="grid md:grid-cols-3 gap-20">
      <div v-for="(item, i) in items" :key="i">
        <!-- If Press Release-->
        <NodePressReleaseDynamicTeaser
          v-if="item.entityBundle === 'press_release'"
          v-bind="item"
          :teaser-style="options.teaserStyle"
        />
        <!-- If News-->
        <NodeNewsDynamicTeaser
          v-else-if="item.entityBundle === 'news'"
          v-bind="item"
          :teaser-style="options.teaserStyle"
        />
      </div>
    </div>

    <div class="flex flex-wrap gap-15">
      <NuxtLink
        v-if="props.contentType.includes('press_release')"
        :to="{
          name: 'press-releases',
          query: { organization: publisherFilter?.id },
        }"
        class="button is-strong is-link mt-50 md:mt-60"
      >
        {{ $texts('allMediaReleases', 'Alle Medienmitteilungen') }}
      </NuxtLink>

      <NuxtLink
        v-if="props.contentType.includes('witness_calls')"
        :to="{
          name: 'bs-call-for-witnesses',
          query: { organization: publisherFilter?.id },
        }"
        class="button is-strong is-link mt-50 md:mt-60"
      >
        {{ $texts('allCallsForWitnesses', 'Alle Zeugenaufrufe') }}
      </NuxtLink>

      <NuxtLink
        v-if="newsLink"
        :to="newsLink"
        class="button is-strong is-link mt-50 md:mt-60"
      >
        {{ $texts('allNews', 'Alle News') }}
      </NuxtLink>
    </div>
  </LayoutSection>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import type {
  ParagraphDynamicTeaserListFragment,
  ParagraphDynamicTeaserListQueryQueryVariables,
} from '#graphql-operations'
import { notNullish } from '@vueuse/core'
import { slugify } from '~/helpers/slugify'
import { buildSectionTitle } from '~/helpers/dynamicTeaserList'

const { options, isEditing } = defineBlokkli({
  bundle: 'dynamic_teaser_list',
  globalOptions: ['showInToc'],
  options: {
    teaserStyle: {
      type: 'radios',
      label: 'Darstellung',
      default: 'reduced',
      options: {
        no_image: 'Ohne Bild',
        reduced: 'Reduziert',
        title_lead_image: 'Titel, Lead, Bild',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
})

const { $texts } = useNuxtApp()

const props = withDefaults(
  defineProps<{
    limit?: ParagraphDynamicTeaserListFragment['limit']
    contentType?: ParagraphDynamicTeaserListFragment['contentType']
    organisationFilter?: ParagraphDynamicTeaserListFragment['organisationFilter']
    tagsFilter?: ParagraphDynamicTeaserListFragment['tagsFilter']
    topicsFilter?: ParagraphDynamicTeaserListFragment['topicsFilter']
    publisherFilter?: ParagraphDynamicTeaserListFragment['publisherFilter']
  }>(),
  {
    limit: 3,
    contentType: () => ['press_releases'],
    organisationFilter: () => [],
    tagsFilter: () => [],
    topicsFilter: () => [],
    publisherFilter: undefined,
  },
)

function getFilterIds(items: { id?: string }[]): string[] {
  return items.map((item) => item?.id).filter(notNullish)
}

const orgIds = computed<string[]>(() => getFilterIds(props.organisationFilter))

const newsLink = computed<RouteLocationRaw | undefined>(() => {
  if (!props.contentType.includes('news')) {
    return
  }

  return {
    name: 'news',
    query: orgIds.value.length
      ? {
          organization: orgIds.value.join(':'),
        }
      : undefined,
  }
})

const queryArgs = computed<ParagraphDynamicTeaserListQueryQueryVariables>(
  () => {
    const args: ParagraphDynamicTeaserListQueryQueryVariables = {
      limit: props.limit,
      contentType: props.contentType,
    }

    const topicIds = getFilterIds(props.topicsFilter)
    const tagIds = getFilterIds(props.tagsFilter)

    // Filter by Organisations
    if (orgIds.value.length) {
      args.organisationFilter = orgIds.value
    }

    // Filter by Topics.
    if (topicIds.length) {
      args.topicsFilter = topicIds
    }

    // Filter by Tags.
    if (tagIds.length) {
      args.tagsFilter = tagIds
    }

    return args
  },
)

const { data: items } = await useAsyncGraphqlQuery(
  'paragraphDynamicTeaserListQuery',
  queryArgs,
  {
    transform: (v) =>
      v.data.dynamicTeaserList.items
        ? v.data.dynamicTeaserList.items
            .map((i) => (i && 'entityBundle' in i ? i : null))
            .filter(notNullish)
        : [],

    graphqlCaching: {
      client: true,
    },
  },
)

const title = computed(() => {
  return buildSectionTitle(props.contentType)
})

const id = computed(() => slugify(title.value))

defineOptions({
  name: 'ParagraphDynamicTeaserList',
})
</script>
